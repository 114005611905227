import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';

import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { Global } from './global';

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  baseURL = this.global.baseURL;
  constructor(public global: Global, private http: Http) { }


  login(LoginData: any) {
    // var data = JSON.stringify({ email: LoginData.email, password: LoginData.password });
    let body = new URLSearchParams();
    body.set('email', LoginData.email);
    body.set('password', LoginData.password);

    var link = this.baseURL + 'user/login_auth';
    // console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  logout() {
    // var data = JSON.stringify({ email: LoginData.email, password: LoginData.password });
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('notificationToken', this.global.NotificationToken);

    var link = this.baseURL + 'user/do_logout';
    // console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  GetNotificationsCheck() {
    // var data = JSON.stringify({ email: LoginData.email, password: LoginData.password });
    let body = new URLSearchParams();
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('notificationToken', this.global.NotificationToken);

    var link = this.baseURL + 'vehicle/stop_notifications';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }


  forgetPassword(form: any) {
    let body = new URLSearchParams();
    body.set('email', form.email);

    var link = this.baseURL + 'user/forgot_password';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  shipmentList() {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('type', 'driver');

    var link = this.baseURL + 'shipment/list_data';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  shipmentDetails(id) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('trackingId', id);

    var link = this.baseURL + 'tracking/status';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  shipmentUpdate(id, status, description, eta) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('shipmentId', id);
    body.set('status', status);
    body.set('description', description);
    body.set('eta', eta);

    var link = this.baseURL + 'shipment/update_tracking';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  shipmentAcknowledge(id) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('shipmentId', id);
    body.set('type', 'driver');

    var link = this.baseURL + 'shipment/update_acknowledgement';
    console.log("data", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  userLocation(coordinates, provider, time, speed) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('coordinates', coordinates);
    body.set('recordedAt', time);
    body.set('provider', provider);
    body.set('speed', speed);

    var link = this.baseURL + 'user/save_location';
    console.log("Location server", body);
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  update_notification_token(notificationToken) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('notificationToken', notificationToken);

    var link = this.baseURL + 'user/update_notification_token';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  list_dashboard_data() {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    
    var link = this.baseURL + 'tracking/list_dashboard_data';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  clear_notifications(vehicleId) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehicleId);
    
    var link = this.baseURL + 'vehicle/clear_notifications';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  update_notification_settings(vehicleId,notificationSettings,notificationOn,temperatureProbes ,temperatureMax , temperatureMin) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehicleId);
    body.set('notificationSettings', notificationSettings);
    body.set('notificationOn', notificationOn);
    body.set('temperatureProbes', temperatureProbes);
    body.set('temperatureMax', temperatureMax);
    body.set('temperatureMin', temperatureMin);

    
    var link = this.baseURL + 'vehicle/update_notification_settings';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_tracking_details(vehicleId) {
    let body = new URLSearchParams();

    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehicleId);

    var link = this.baseURL + 'tracking/get_tracking_details';
    return this.http.post(link, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }
  add_location(addForm :any, address){
    let url = this.baseURL + "location/add";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('name', addForm.name);
    body.set('address', addForm.address);
    body.set('latitude', addForm.latitude);
    body.set('longitude', addForm.longitude);
    body.set('radius', addForm.radius);
    body.set('address', address);
    body.set('monitoringStatus', addForm.monitoringStatus);
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }
   get_location_list(){
    let url = this.global.baseURL + "location/list_locations";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }
   update_location(locationId,name,address,latitude,longitude,radius, monitoringStatus){
    let url = this.baseURL + "location/update";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('locationId', locationId);
    body.set('name', name);
    body.set('address', address);
    body.set('latitude', latitude);
    body.set('longitude', longitude);
    body.set('radius', radius);
    body.set('monitoringStatus', monitoringStatus);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }
  
   del_location(locationId){
    let url = this.baseURL + "location/delete";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('locationId', locationId);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }
   location_monitoring(monitoringStatus){
    let url = this.global.baseURL + "location/update_location_monitoring";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    // body.set('locationId', '0');
    body.set('monitoringStatus', monitoringStatus);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }
   list_vehicles(){
    let url = this.global.baseURL + "vehicle/list_data";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   update_vehicle_icon(vehcileId, icon){
    let url = this.global.baseURL + "vehicle/update_icon";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehcileId);
    body.set('icon', icon);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   getFuelReport(vehcileId, start_date, end_date){
    let url = this.global.baseURL + "tracking/get_fuel_report";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehcileId);
    body.set('start', start_date);
    body.set('end', end_date);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   list_vehicle_location_data(){
    let url = this.global.baseURL + "tracking/list_vehicle_location_data";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   get_device_trips_list(vehicleId,start,end){
    let url = this.global.baseURL + "/tracking/get_device_trips_list";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('vehicleId', vehicleId);
    body.set('start', start);
    body.set('end', end);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   get_driver_list(){
    let url = this.global.baseURL + "user/list_data";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('type', 'driver');
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }

   get_driver_log(otherId){
    let url = this.global.baseURL + "logs/get_driver_log";
    let body = new URLSearchParams();
 
    body.set('userId', this.global.userId);
    body.set('authToken', this.global.authToken);
    body.set('otherId', otherId);
    
    return this.http.post(url,body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
   }


  private catchError(error: Response | any) {
    console.log(error);
    return Observable.throw(error.json().error || "Server error");
  }

  private logResponse(res: Response | any) {
    console.log("server response", res);
    // console.log("server response - parsed", res.json());
  }

  private extractData(res: Response) {
    return res.json();
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Global } from './global';
import { ServerService } from './server.service';
import { isArray } from 'rxjs/internal/util/isArray';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      userType: 'shipper'
    },
    {
      title: 'Locations',
      url: '/list-location',
      userType: 'shipper'
    },
    {
      title: 'Vehicles',
      url: '/vehicles',
      userType: 'shipper'
    },
    {
      title: 'Map',
      url: '/vehicle-list',
      userType: 'shipper'
    },
    {
      title: 'Playlist',
      url: '/play-list',
      userType: 'shipper'
    },
    {
      title: 'Drivers',
      url: '/drivers',
      userType: 'shipper'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private nativeStorage: NativeStorage,
    public global: Global,
    public server: ServerService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.global.isApp = (!document.URL.startsWith('http') || document.URL.startsWith('http://localhost:8080'));

      this.statusBar.styleDefault();
      this.splashScreen.hide();

      this.nativeData();
      if(localStorage.getItem("GetNotificationsCheck") != null && localStorage.getItem("GetNotificationsCheck") != undefined){
        this.global.GetNotificationsCheck = JSON.parse(localStorage.getItem("GetNotificationsCheck")).key;
      }
      else{
        this.global.GetNotificationsCheck = false;
      }
      
    });
  }
  onSuccessSound() {

  }
  onErrorSound() {

  }


  do_logout() {
    this.global.presentToast('Please wait...', 500);
    let response = this.server.logout();
    response.subscribe(data => {
      if (data.code == 200 && data.data.success) {
        this.global.presentToast(data.data.message, 1000);
        this.router.navigate(['login']);
      }
    }, error => {
      // loading.dismiss();
      console.log(error);
      this.global.presentToast("Something went wrong check your internet connection.");
    });
  }

  nativeData() {
    this.nativeStorage.getItem('user').then(data => {
      console.log('User ->', data);
      this.global.email = data.email;
      this.global.password = data.password;
      this.global.userId = data.userId;
      this.global.name = data.name;
      this.global.phone = data.phone;
      this.global.authToken = data.authToken;
      this.global.company = data.company;
      this.global.userType = data.userType
      this.navigate();
      // this.router.navigate(['home']);
    }, error => {
      // we don't have the user data so we will ask him to log in
      this.router.navigate(['login']);
      // this.router.navigate(['stream/2B2B1QFX']);
    }).catch(err => { console.log(err); });
  }
  navigate() {
    // if (this.global.isApp) {
      if (this.global.userType == 'shipper') {
        this.router.navigate(['dashboard']);
      }
      else {
        this.router.navigate(['home']);
      }
    // }
    // else {
      // not an app so navigating to map view
      // this.router.navigate(['vehicle-list']);
      // this.router.navigate(['dashboard']);

    // }
  }



}
